import React, { Component, Fragment } from 'react';

import Title from '../components/Title';
import Message from '../components/Message';

const WhatWeDo = props => (
  <div className="c-content-box c-size-sm">
    <div className="container">
      <Title main={props.title} />
      <div className="row display-flex">
        <div className="col-md-4">
          <div className="c-content-media-1 c-bordered" style={{ height: '100%' }}>
            <div className="c-content-label c-font-uppercase c-font-bold c-theme-bg">{props.tag}</div>
            <h2 className="c-title c-font-uppercase c-font-bold">{props.title}</h2>

            <p dangerouslySetInnerHTML={{ __html: props.description }} />
          </div>
        </div>
        <div className="col-md-8">
          <div className="c-content-media-2-slider">
            <img src={props.image} alt={props.title} width="100%" />
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Benefits = props => (
  <div className="c-content-box c-size-sm">
    <div className="container">
      <Title main={`Why choose ${props.title}?`} />
      <div className="c-body c-bg-white c-padding-20">
        <div className="row display-flex">
          <div className="col-md-6">
            <ul className="c-content-list-1 c-theme c-separator-dot">
              {props.col1.map((b, idx) => (
                <li key={idx} className="c-bg-before-purple">
                  {b}
                </li>
              ))}
            </ul>
          </div>
          <div className="col-md-6">
            <ul className="c-content-list-1 c-theme c-separator-dot">
              {props.col2.map((b, idx) => (
                <li key={idx} className="c-bg-before-purple">
                  {b}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
);

class Service extends Component {
  render() {
    const { title, description, tag, image, col1, col2, artwork } = this.props;

    return (
      <Fragment>
        <WhatWeDo tag={tag} title={title} description={description} image={image} />

        <Benefits title={title} col1={col1} col2={col2} />

        {artwork && <Message text={artwork} />}
      </Fragment>
    );
  }
}

export default Service;
