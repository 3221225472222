import React from 'react';

const Message = props => (
  <div className="c-content-box c-size-sm">
    <div className="container">
      <div className="c-content-panel">
        <div className="c-body">
          <blockquote className="c-theme-border c-font-white">
            <p dangerouslySetInnerHTML={{ __html: props.text }} />
          </blockquote>
        </div>
      </div>
    </div>
  </div>
);

export default Message;
