import React from 'react';

import Layout from '../../components/Layout';
import Service from '../../components/Service';

import Image from '../../../assets/images/services/thermal.jpg';

const col1 = [
  'Wide variety of base materials for various uses',
  'Ideal for overprinting labels with consecutive numbers and barcodes',
  'Suitable for printing all barcode symbologies'
];

const col2 = [
  'Print size up to 210 x 210 mm for die cut labels or 210 x 500mm for continuous print',
  'No printing plates required'
];

const Thermal = () => {
  const title = 'Thermal Labels';

  const metaDescription =
    'Thermal transfer printing from Calf Hey. With a range of options we can customise labels to your specific details. No plates required.';
  const metaKeywords =
    'thermal, printing, print, labels, label, variable, consecutive, numbering, number, barcode, barcodes, barcoding, symbology, cheap, quality, overprint, overprinting';

  return (
    <Layout title={title} description={metaDescription} keywords={metaKeywords}>
      <Service
        tag="Calf Hey Design"
        title={title}
        description={`
          <p>Thermal printing requires no printing plates and is so flexible that it allows us to produce every label on a roll as a unique design if necessary.</p>
          <p>Ideal for consecutive numbering and barcoding in any quantity from 10 labels to whatever you require.</p>        
          <p>With a range of options available, we can customise labels to your specific details. However, we can only print sequential numbers and barcodes in black.</p>
        `}
        image={Image}
        col1={col1}
        col2={col2}
        artwork={false}
      />
    </Layout>
  );
};

export default Thermal;
